var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"preview-container",attrs:{"id":"pinterest-preview"}},[_c('div',{staticClass:"preview-ui"},[_c('div',{staticClass:"top-elements"},[_c('div',{staticClass:"top-icon"},[_c('icon',{attrs:{"size":"20","color":"white","name":"chevron-left"}})],1),_c('div',{staticClass:"top-dots"},_vm._l((3),function(dot,index){return _c('icon',{key:index,staticClass:"top-dot",attrs:{"size":"7","color":"white","name":"circle"}})}),1)]),(_vm.isCarousel)?_c('div',{ref:"container",staticClass:"carousel-container inset-shadow",class:{ 'larger-image': _vm.isLargerImage,
                      'smaller-block': _vm.smallerBlock,
                      'nine-by-sixteen': _vm.nineBySixteen,
                      'not-nine-by-sixteen': !_vm.nineBySixteen }},[_c('carousel',{attrs:{"touch":true,"per-page":1,"mouse-drag":true,"navigation-enabled":true,"pagination-enabled":true,"navigation-next-label":"","navigation-prev-label":""}},_vm._l((_vm.adData.creatives),function(slide,index){return _c('slide',{key:index,staticClass:"carousel-slide"},[_c('div',{staticClass:"pinterest-image-container"},[_c('img',{ref:"image",refInFor:true,staticClass:"carousel-image",attrs:{"alt":"Pinterest preview image","src":slide.media_url},on:{"load":function($event){return _vm.setDimensions()}}})])])}),1)],1):_c('div',{ref:"container",staticClass:"static-image-container inset-shadow",class:{ 'larger-image': _vm.isLargerImage,
                      'nine-by-sixteen': _vm.nineBySixteen,
                      'not-nine-by-sixteen': !_vm.nineBySixteen }},[(!_vm.isVideo)?_c('div',{staticClass:"pinterest-image-container"},[_c('img',{ref:"image",staticClass:"carousel-image",class:{ 'border-radius-20': _vm.nineBySixteen },attrs:{"alt":"Pinterest preview image","src":_vm.mediaUrl},on:{"load":function($event){return _vm.setDimensions()}}})]):_vm._e(),(_vm.isVideo)?_c('div',{staticClass:"pinterest-image-container"},[(!_vm.previewingExistingAds && _vm.controlsActive)?_c('div',{staticClass:"play-wrapper",on:{"click":_vm.playVideo}},[_c('div',{staticClass:"play-button"},[(!_vm.hidePlayIcon)?_c('icon',{attrs:{"size":"40","color":"white","name":"audio-play"}}):_c('div',[_c('div',{staticClass:"previews-pause-bar"}),_c('div',{staticClass:"previews-pause-bar"})])],1)]):_vm._e(),_c('video',{key:_vm.mediaUrl,ref:"video",staticClass:"carousel-image",class:{ 'border-radius-20': _vm.nineBySixteen },attrs:{"poster":_vm.previewingExistingAds ? _vm.mediaUrl : null,"loop":""},domProps:{"muted":_vm.muted}},[_c('source',{attrs:{"src":_vm.mediaUrl,"type":"video/mp4"}})])]):_vm._e()]),(_vm.isVideo && _vm.previewingExistingAds)?_c('a',{staticClass:"play-wrapper",attrs:{"href":_vm.videoRedirectLink,"target":"_blank"}},[_c('div',{staticClass:"play-button"},[_c('icon',{attrs:{"size":"40","color":"white","name":"audio-play"}})],1)]):_vm._e(),_c('div',{staticClass:"bottom-block",class:{ 'smaller-block': _vm.smallerBlock,
                      'bottom-block-floating': _vm.bottomBlockFloating,
                      'bottom-block-stuck-to-bottom': !_vm.bottomBlockFloating }},[_c('div',{staticClass:"bottom-icon"},[_c('icon',{attrs:{"size":"15","color":"black","name":"chevron-up"}})],1),_c('a',{staticClass:"bottom-visit",attrs:{"href":_vm.ctaLink,"target":"_blank"}},[_vm._v(" Visit site ")]),_c('div',{staticClass:"bottom-desc"},[_vm._v(" "+_vm._s(_vm.title)+" ")])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }