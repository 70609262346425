<template>
    <div
        id="pinterest-preview"
        class="preview-container">
        <div class="preview-ui">
            <div class="top-elements">
                <div class="top-icon">
                    <icon
                        size="20"
                        color="white"
                        name="chevron-left" />
                </div>
                <div class="top-dots">
                    <icon
                        v-for="(dot, index) in 3"
                        :key="index"
                        class="top-dot"
                        size="7"
                        color="white"
                        name="circle" />
                </div>
            </div>
            <div
                v-if="isCarousel"
                ref="container"
                :class="{ 'larger-image': isLargerImage,
                          'smaller-block': smallerBlock,
                          'nine-by-sixteen': nineBySixteen,
                          'not-nine-by-sixteen': !nineBySixteen }"
                class="carousel-container inset-shadow">
                <carousel
                    :touch="true"
                    :per-page="1"
                    :mouse-drag="true"
                    :navigation-enabled="true"
                    :pagination-enabled="true"
                    navigation-next-label=""
                    navigation-prev-label="">
                    <slide
                        v-for="(slide, index) in adData.creatives"
                        :key="index"
                        class="carousel-slide">
                        <div class="pinterest-image-container">
                            <img
                                ref="image"
                                class="carousel-image"
                                alt="Pinterest preview image"
                                :src="slide.media_url"
                                @load="setDimensions()">
                        </div>
                    </slide>
                </carousel>
            </div>
            <div
                v-else
                ref="container"
                :class="{ 'larger-image': isLargerImage,
                          'nine-by-sixteen': nineBySixteen,
                          'not-nine-by-sixteen': !nineBySixteen }"
                class="static-image-container inset-shadow">
                <!-- Update the logic for the 2-3 here -->
                <div
                    v-if="!isVideo"
                    class="pinterest-image-container">
                    <img
                        ref="image"
                        :class="{ 'border-radius-20': nineBySixteen }"
                        class="carousel-image"
                        alt="Pinterest preview image"
                        :src="mediaUrl"
                        @load="setDimensions()">
                </div>
                <div
                    v-if="isVideo"
                    class="pinterest-image-container">
                    <div
                        v-if="!previewingExistingAds && controlsActive"
                        class="play-wrapper"
                        @click="playVideo">
                        <div class="play-button">
                            <icon
                                v-if="!hidePlayIcon"
                                size="40"
                                color="white"
                                name="audio-play" />
                            <div v-else>
                                <div class="previews-pause-bar" />
                                <div class="previews-pause-bar" />
                            </div>
                        </div>
                    </div>
                    <video
                        :key="mediaUrl"
                        ref="video"
                        :poster="previewingExistingAds ? mediaUrl : null"
                        loop
                        :muted="muted"
                        :class="{ 'border-radius-20': nineBySixteen }"
                        class="carousel-image">
                        <source
                            :src="mediaUrl"
                            type="video/mp4">
                    </video>
                </div>
            </div>
            <a
                v-if="isVideo && previewingExistingAds"
                class="play-wrapper"
                :href="videoRedirectLink"
                target="_blank">
                <div class="play-button">
                    <icon
                        size="40"
                        color="white"
                        name="audio-play" />
                </div>
            </a>
            <div
                :class="{ 'smaller-block': smallerBlock,
                          'bottom-block-floating': bottomBlockFloating,
                          'bottom-block-stuck-to-bottom': !bottomBlockFloating }"
                class="bottom-block">
                <div class="bottom-icon">
                    <icon
                        size="15"
                        color="black"
                        name="chevron-up" />
                </div>
                <a
                    :href="ctaLink"
                    target="_blank"
                    class="bottom-visit"> Visit site </a>
                <div class="bottom-desc">
                    {{ title }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import Icon from '@/components/globals/Icon';
import { Carousel, Slide } from 'vue-carousel';
import { getImageFraction, getVideoFraction } from '@/helpers/getMediaFraction';

export default {
    name: 'PinterestAdPreview',
    components: {
        Icon,
        Carousel,
        Slide,
    },
    props: {
        adData: {
            type: [Object, Array],
            required: true,
        },
        muted: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            maxTitleLength: 49,
            elipsis: '...',
            punctuationRegex: /[.,/#!?$%^&*;:{}=\-_`~()]/g,
            imageWidth: 0,
            imageHeight: 0,
            containerHeight: 0,
            containerWidth: 0,
            nineBySixteen: false,
            hidePlayIcon: false,
            controlsActive: true,
        };
    },
    computed: {
        ...mapState({
            adIndex: (state) => state.adStudio.adIndex,
        }),
        creatives() {
            return this.adData?.creatives[0];
        },
        title() {
            const title = this.adData?.headline || this.creatives?.title;
            const words = title?.split(' ');
            let trimmedText = '';

            if (!words) {
                return '';
            }

            for (let i = 0; i < words.length; i++) {
                const currentWord = words[i];

                if (trimmedText.length + currentWord.length > this.maxTitleLength) {
                    const previousWord = words[i - 1];

                    // Remove any punctuation from the previous word
                    const lastCharacter = previousWord.charAt(previousWord.length - 1);
                    if (this.punctuationRegex.test(lastCharacter)) {
                        words[i - 1] = previousWord.slice(0, -1);
                    }

                    // Remove the last word if it would be cut off
                    if (trimmedText.length !== 0) {
                        trimmedText = trimmedText.slice(0, -1);
                    }

                    // Add ellipsis to previous word
                    trimmedText += this.elipsis;
                    break;
                }

                trimmedText += currentWord + ' ';
            }

            // If the text is already shorter than the limit, return it
            if (trimmedText.length <= this.maxTitleLength) {
                return trimmedText;
            }

            // Remove any punctuation from the last word
            const lastCharacter = trimmedText.charAt(trimmedText.length - 2);
            if (this.punctuationRegex.test(lastCharacter)) {
                trimmedText = trimmedText.slice(0, -2) + ' ';
            }

            // Add ellipsis to the end
            return trimmedText.trim() + this.elipsis;
        },
        isVideo() {
            return this.creatives?.media_type === 'VIDEO' || this.firstAsset?.asset_type?.name === 'video';
        },
        isCarousel() {
            return this.adData.render_type === 'COLLECTION';
        },
        ctaLink() {
            return this.adData?.cta_link || this.creatives?.cta_link;
        },
        videoRedirectLink() {
            const pinId = this.adData?.pin_id;
            return `https://www.pinterest.com/pin/${pinId}`;
        },
        firstAsset() {
            return this.creatives?.assets?.[0];
        },
        mediaUrl() {
            return this.creatives?.media_url || this.firstAsset?.url || '';
        },
        isLargerImage() {
            return (this.imageHeight / this.imageWidth) > (this.carouselHeight / this.carouselWidth);
        },
        smallerBlock() {
            return this.title.length < 25;
        },
        previewingExistingAds() {
            // New ad building just uses url for the asset
            // media_url us used when previewing existing ads from campaign managment
            return this.creatives?.media_url;
        },
        bottomBlockFloating() {
            if (this.isCarousel) {
                return false;
            }

            if (this.nineBySixteen) {
                return true;
            }

            return false;
        }
    },
    watch: {
        adIndex() {
            this.initPlatformMediaStyles();
        },
    },
    mounted() {
        this.initPlatformMediaStyles();
        if (this.isVideo) {
            this.$emit('set-video-type');
        }
    },
    methods: {
        setDimensions() {
            this.imageWidth = this.$refs?.image?.naturalWidth;
            this.imageHeight = this.$refs?.image?.naturalHeight;

            this.carouselHeight = this.$refs?.container?.offsetHeight;
            this.carouselWidth = this.$refs?.container?.offsetWidth;
        },
        async initPlatformMediaStyles() {
            const fraction = this.isVideo ? await getVideoFraction(this.mediaUrl) : await getImageFraction(this.mediaUrl);
            this.nineBySixteen = fraction < 0.59; // @todo use the function in src/helpers/getAssetStyles.js
        },
        playVideo() {
            if (this.hidePlayIcon) {
                this.$refs.video.pause();
                this.hidePlayIcon = false;
            } else {
                this.$refs.video.play();
                this.hidePlayIcon = true;
            }
        },
        showPlayButtons() {
            this.controlsActive = true;
        },
        hidePlayButtons() {
            if (this.hidePlayIcon) {
                this.controlsActive = false;
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.preview-container {
    position: relative;
    width: 375px;
    display: flex;
    flex-direction: column;
    background-color: $black;
    border-radius: 20px;
    overflow: hidden;
    color: $white;
    box-shadow: 0 0 15px 5px rgba(0, 0, 0, 0.2);
    @media only screen and (max-width: 400px) {
        width: 320px;
    }

    .preview-ui {
        position: relative;
        display: flex;
        height: 100%;
        flex-direction: column;

        .carousel-container {
            position: relative;
            overflow: hidden;

            &.nine-by-sixteen {
                height: 667px; // 9:16
            }

            &.not-nine-by-sixteen {
                height: 560px; // 9:16
            }

            .pinterest-image-container {
                width: 100%;
                height: 100%;
                border-radius: 20px;
                background-color: #000000;

                .carousel-image {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                    border-radius: 20px;
                }
            }
        }

        .static-image-container {
            position: relative;
            overflow: hidden;

            &.nine-by-sixteen {
                height: 100%; // 9:16
                padding-bottom: 100px;
                .pinterest-image-container {
                    display: flex;
                    align-items: normal;
                }
            }

            &.not-nine-by-sixteen {
                height: 560px; // !9:16
                .pinterest-image-container {
                    height: 100%;
                    display: flex;
                    align-items: center;
                }
            }

            &.larger-image {
                top: 0%;

                .carousel-image {
                    position: relative;
                    width: 100%;
                    border-radius: 20px;
                }
            }

            .pinterest-image-container {
                background-color: #000000;

                .carousel-image {
                    width: 100%;
                }
            }
        }
    }
}



.inset-shadow {
    &:after {
        box-shadow: 0px 70px 70px -70px #888, inset 0px -50px 70px -70px #888;
        content: "";
        display: block;
        position: absolute;
        top: 0;
        width: 100%;
        border-radius: 20px;
    }
}
.top-elements {
    position: absolute;
    top: 25px;
    z-index: 9;
    left: 25px;
    right: 25px;
    display: flex;
    justify-content: space-between;
}
.top-dot {
    margin-left: 3px;
}

.border-radius-20 {
    border-radius: 20px;
}
.bottom-block-stuck-to-bottom {
    position: relative;
}
.bottom-block-floating {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}
.bottom-block {
    background: white;
    z-index: 10;
    color: black;
    text-align: center;
    padding: 15px;
    border-radius: 20px;
    height: 150px;

    &.smaller-block {
        height: 126px;
    }
}
.bottom-visit {
    font-size: 15px;
    display: inline-block;
    margin-bottom: 5px;
    font-weight: 550;
    color: black;
    text-decoration: none;
}
.bottom-desc {
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
}
.play-wrapper {
    position: absolute;
    top:0;
    right:0;
    left:0;
    bottom: 0;
    cursor: pointer;
    z-index: 3;
    .play-button {
        top:50%;
        left:50%;
        margin-top:-20px;
        margin-left:-20px;
        cursor: pointer;
        position: relative;
        z-index: 2;
    }
    &:after {
        content:'';
        position: absolute;
        width: 85px;
        border-radius: 50%;
        border: 1px solid white;
        height: 85px;
        top:50%;
        left:50%;
        margin-top:-44px;
        margin-left:-45px;
        background: rgba(0,0,0,0.5);
        cursor: pointer;
        z-index: 1;
    }
}
</style>

<style lang="scss">
#pinterest-preview {
    .VueCarousel {
        height: 100%;
        justify-content: center;
        position: relative;
        z-index: 9;
    }
    .VueCarousel-inner {
        max-height: 100%;
    }
    .VueCarousel-pagination {
        position: absolute;
        bottom: 40px;
        z-index: 3001;
    }
    .VueCarousel-dot {
        padding: 3px !important;
        width: 8px !important;
        height: 8px !important;
        opacity: 0.6;
    }
    .VueCarousel-dot--active {
        opacity: 1;
        background-color: $white !important;
    }
}
</style>
